import { Routings } from '@/common/routings';
import { getPartneridForSessionStorage } from '@/common/utilities';
import Button from '@/components/atoms/Button';
import ErrorMsg from '@/components/atoms/ErrorMessage';
import PageTitle from '@/components/atoms/PageTitle';
import RadioButton from '@/components/atoms/RadioButton';
import Select from '@/components/atoms/Select';
import SubmitButton from '@/components/atoms/SubmitButton';
import { TermsInArea } from '@/components/atoms/Terms';
import { Main } from '@/components/layout/common';
import AddressSelectModal from '@/components/organisms/SearchByMansionName/AddressSelectModal';
import { Breakpoints, FloorPlanArray, StructureArray, Years } from '@/constants/constants';
import * as assessment from '@/state/modules/assessment';
import * as doorman from '@/state/modules/doorman';
import theme from '@/styles/theme';
import { jpFormatToyyyymmddString } from '@/utils/numberFormatter';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from 'gatsby';
import {
  Assessment,
  AssessmentAddress,
  AssessmentMansion,
  AssessmentRoom,
  BathroomType,
  RentManagementFeeType,
  RentManagementStatus,
  RentType,
  Structure,
  TenancyStatus,
} from 'openapi/kusabi-frontgw-spec';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { FormState, schema } from './state';

const InnerMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 5% 120px 5%;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0px 3.5% 60px 3.5%;
  }
`;

const GuideTextWrapepr = styled.div`
  width: 100%;
  max-width: 1062px;
  display: flex;
  margin-top: 40px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 30px;
  }
`;

const GuideText = styled.label`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.normal_20_32};
  }
`;

const Th = styled.th`
  width: 310px;
  vertical-align: middle;
  padding-left: 20px;
  padding-right: 20px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_32};
  color: #333333;
  border-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 50px;
    vertical-align: baseline;
    padding: 0px 0px 20px 0px;
    background: #fff;
    font: ${theme.fonts.bold_20_32};
    border-bottom: 1px solid #a2aab0;
    margin-top: 10px;
  }
`;

const LineTh = styled.th`
  width: 310px;
  height: 67px;
  vertical-align: middle;
  padding: 33px 20px 0px 20px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_32};
  color: #333333;
  border-top: 0px;
  border-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 50px;
    vertical-align: baseline;
    padding: 0px 0px 20px 0px;
    background: #fff;
    font: ${theme.fonts.bold_20_32};
    border-bottom: 1px solid #a2aab0;
    margin-top: 30px;
  }
`;

const MansionDetailTh = styled.th`
  width: 310px;
  height: 62px;
  vertical-align: middle;
  padding: 33px 20px 0px 20px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_24};
  color: #333333;
  border-top: 0px;
  border-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 50px;
    vertical-align: baseline;
    padding: 0px 0px 20px 0px;
    background: #fff;
    font: ${theme.fonts.bold_20_32};
    border-bottom: 1px solid #a2aab0;
    margin-top: 30px;
  }
`;

const InnerTh = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  label {
    text-align: left;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding-left: 0px;
  }
`;

const ThLabel = styled.label`
  text-align: left;
  font: ${theme.fonts.bold_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_20_32};
  }
`;

const FormContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #333333;
  margin-bottom: 0px;
  border-bottom: 1px solid #a2aab0;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    border-bottom: 0px;
  }
`;

const RentPriceRow = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;
  height: 65px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: max-content;
    display: flex;
    flex-direction: column;
    border: none;
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
`;

const MansionDetailRow = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 0px;
  height: 68px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: max-content;
    display: flex;
    flex-direction: column;
    border: none;
    &:not(:first-child) {
      margin-top: 0px;
    }
  }
`;

const LineRow = styled.tr`
  border-top: 0px
  border-bottom: 0px
  height: 67px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: max-content;
    display: flex;
    flex-direction: column;
    border: none;
    &:not(:first-child) {
      margin-top: 0px;
    }
  }
`;

const Row = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;
  height: 90px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: max-content;
    display: flex;
    flex-direction: column;
    border: none;
    &:not(:first-child) {
      margin-top: 0px;
    }
  }
`;

const Required = styled.span`
  display: inline-block;
  height: 20px;
  background-color: #fff;
  border: 1px solid #d1a827;
  border-radius: 4px;
  font: ${theme.fonts.bold_10_20};
  color: #d1a827;
  min-width: 10px;
  padding: 3px 7px;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
`;

const MansionDetailTdFirstLine = styled.td`
  height: 40px;
  padding: 0px 0px 0px 3.5%;
  margin: 25px 0px 20px 0px;
  border-top: 0px;
  border-bottom: 0px;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: max-content;
    padding: 0px;
    margin: 0px;
  }
`;

const MansionDetailTd = styled.td`
  height: 40px;
  padding: 0px 0px 0px 3.5%;
  margin-top: 5px;
  margin-bottom: 15px;
  border-top: 0px;
  border-bottom: 0px;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: max-content;
    margin: 0px;
    padding: 0px;
    flex-direction: column;
  }
`;

const LineTd = styled.td`
  height: 40px;
  padding: 25px 0px 60px 3.5%;
  border-top: 0px;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 70px;
    height: max-content;
    padding: 0px;
  }
`;

const RentManagementFeeTd = styled.td`
  padding: 30px 0px 0px 28px;
  border-top: 0px;
  border-bottom: 0px;
  vertical-align: baseline;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: max-content;
    padding: 20px 0px 0px 0px;
  }
`;

const Td = styled.td`
  padding-left: 28px;
  padding-top: 30px;
  border-bottom: 0px;
  display: flex;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    padding: 20px 0px 0px 0px;
    flex-direction: column;
  }
`;

const AddressTd = styled.td`
  padding-left: 2%;
  padding-top: 35px;
  border-bottom: 0px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    margin-top: 20px;
  }
`;

const LineInnerTd = styled.div`
  width: 436px;
  height: 40px;
  display: flex;
  flex-direction: row;
  border-top: 0px;
  border-bottom: 0px;
  align-items: center;
  font: ${theme.fonts.normal_16_24};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 100px;
    margin: 0px;
    flex-direction: column;
    align-items: baseline;
  }
`;

const InnerTd = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-top: 0px;
  border-bottom: 0px;
  font: ${theme.fonts.normal_16_24};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 100%;
    margin: 0px;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
  }
`;

const InnerWalkMinuteTd = styled.div`
  width: 436px;
  height: 24px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-top: 0px;
  border-bottom: 0px;
  font: ${theme.fonts.normal_16_24};
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 40px;
    margin: 20px 0px 0px 0px;
    align-items: center;
    justify-content: space-between;
  }
`;

const SmallTextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    align-items: flex-end;
  }
`;

const ControllerTextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
  flex-direction: row;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const InnerTdLabel = styled.label`
  display: block;
  width: 109px;
  text-align: left;
  font: ${theme.fonts.normal_16_24};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_16_24};
    margin: 15px 0px 15px 0px;
  }
`;

const MansionTextField = styled.input`
  width: 408px;
  height: 40px;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;

  &::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_24};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const SmallTextField = styled.input`
  width: 80px;
  height: 40px;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;

  &::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_32};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const TextField = styled.input`
  width: 190px;
  height: 30px;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;
  &::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_32};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const InnerTdWrapper = styled.div`
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const BuildDateWrapper = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const AddressArea = styled.div`
  margin-top: -34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    flex-direction: column;
    text-align: left;
    margin-top: 0px;
  }
`;

const TdLabel = styled.label`
  text-align: left;
  margin-left: 10px;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  white-space: nowrap;
`;

const ReSelectAddressButton = styled.button`
  width: 190px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  text-align: center;
  font: ${theme.fonts.bold_14_33};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  white-space: nowrap;
  margin-right: 1.5%;
  margin-left: auto;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  }
`;

const RadioButtonArea = styled.div`
  display: flex;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-top: 0px;
    flex-direction: column;
  }
`;

const RadioButtonDetailArea = styled.div`
  display: flex;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-top: 0px;
    flex-direction: column;
  }
  radioButton {
    width: 100%;
  }
`;

const TextFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 30px;
  label {
    white-space: nowrap;
    font: ${theme.fonts.normal_16_24};
    color: #333333;
    padding-left: 10px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const RentTypeSelect = styled(Select)`
  width: 190px;
  margin-bottom: 15px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-bottom: 0px;
  }
`;

const RentManagementFeeTextField = styled.input`
  width: 190px;
  height: 30px;
  padding: 0 6px 0 6px;
  background: #ffffff;
  font: ${theme.fonts.normal_16_32};
  border: 1px solid #cccccc;
  border-radius: 4px;
  &::placeholder {
    text-align: left;
    font: ${theme.fonts.normal_16_32};
    letter-spacing: 0px;
    color: #cccccc;
    opacity: 1;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const AssessmentButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 64px auto 40px auto;
  justify-content: space-around;
  align-items: flex-end;
`;

const RentManagementFeeTypeSelect = styled(Select)`
  width: 64px;
  height: 30px;
  display: inline-flex;
`;

const SelectOption = styled.option`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
`;

const AssessmentButton = styled(Button)`
  width: 299px;
  height: 64px;
  background: #4c586f 0% 0% no-repeat padding-box;
  font: ${theme.fonts.bold_22_33};
  border-radius: 4px;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 73px;
  }
` as typeof Button;

const StyledSelect = styled(Select)`
  width: 299px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const YearSelect = styled(Select)`
  width: 100px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const MonthSelect = styled(Select)`
  width: 80px;
  margin-left: 20px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const ErrorMessage = styled(ErrorMsg)`
  text-align: left;
  height: 17px;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    white-space: break-spaces;
    height: 100%;
  }
`;

const AIAssessmentButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0px auto 40px auto;
  justify-content: space-around;
  align-items: flex-end;
`;

const PickedRentType = { RESIDENT: '入居者支払い賃料', NET_INCOME: '手取り賃料' };

type Props = {
  isFromInformation: boolean;
};

const Condition = ({ isFromInformation }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    resetField,
    control,
    register,
    clearErrors,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      address: '',
      builtDateYear: 2010,
      builtDateMonth: 1,
      tenancyStatus: TenancyStatus.LEASE,
      rentManagementStatus: RentManagementStatus.SUBLEASE,
      rentManagementFeeType: RentManagementFeeType.YEN,
      bathroomType: BathroomType.SEPARATE_BATH_AND_TOILET,
    },
  });

  const dispatch = useDispatch();
  const [assessmentFlg, setAssessmentFlg] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });
  const [prefName, setPrefName] = useState('');
  const [cityName, setCityName] = useState('');
  const [townName, setTownName] = useState('');
  const doormaLines = useSelector(doorman.selectors.getDoormanLines);
  const doormaStations = useSelector(doorman.selectors.getDoormanStations);
  const formValue = useSelector(assessment.selectors.fetchAssessmentFormValue);
  const [isFirstRender, setIsFirstRender] = useState(true); // コンポーネントが1レンダー目であることを示すフラグ
  const personalInfoDetail = useSelector(assessment.selectors.fetchPersonalInfoDetailValue);
  const createAssessment = useSelector(assessment.selectors.createAssessment);

  useEffect(() => {
    if (assessmentFlg) {
      navigate(Routings.result.location, { state: { props: { assessment: createAssessment } } });
    }
  }, [createAssessment]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (isFromInformation) {
      // 個人情報入力画面から戻ってきた時の入力値復元処理
      setIsModalOpen(false);
      const mansionInfo = formValue.assessment?.mansionInfo as AssessmentMansion;
      const addressInfo = formValue.assessment?.addressInfo as Required<AssessmentAddress>;
      const roomInfo = formValue.assessment?.roomInfo as Required<AssessmentRoom>;

      // 物件情報
      setValue('mansionName', mansionInfo?.mansionName);
      setValue('address', addressInfo.townName);
      setPrefName(addressInfo.prefectureName);
      setCityName(addressInfo.cityName);
      setTownName(addressInfo.townName);
      setValue('lineName', addressInfo.lineName);
      doormaLines.lines?.forEach((line) => {
        if (addressInfo?.lineName === line.name) dispatch(doorman.operations.getStations(line.id as number));
      });
      setValue('stationName', addressInfo.stationName);
      setValue('walkMinute', addressInfo.walkMinute);
      setValue('floorPlan', roomInfo.floorPlan);
      setValue('story', roomInfo.story.toString());
      setValue('unitSize', roomInfo.unitSize);
      setValue('bathroomType', roomInfo.bathroomType);
      setValue('builtDateYear', Number(mansionInfo?.builtDate.substring(0, 4)));
      setValue('builtDateMonth', Number(mansionInfo?.builtDate.substring(5, 7)));
      setValue('structure', mansionInfo.structure);

      // 諸条件
      setValue('tenancyStatus', formValue.assessment?.tenancyStatus);
      setValue('rentManagementStatus', formValue.assessment?.rentManagementStatus);
      if (formValue.assessment?.tenancyStatus === TenancyStatus.LEASE) {
        setValue('rentPrice', formValue.assessment?.rentPrice);
        if (formValue.assessment?.rentManagementStatus === RentManagementStatus.OTHER) {
          setValue('rentType', formValue.assessment?.rentType);
          setValue('rentManagementFee', formValue.assessment?.rentManagementFee);
        }
      } else if (formValue.assessment?.tenancyStatus === TenancyStatus.VACANCY) {
        setValue('rentPrice', formValue.assessment?.rentPrice);
        setValue('rentManagementFeeType', formValue.assessment?.rentManagementFeeType);
        if (formValue.assessment?.rentManagementFeeType === RentManagementFeeType.PERCENT) {
          setValue('rentManagementFeeRate', formValue.assessment?.rentManagementFeeRate);
        } else {
          setValue('rentManagementFeeYen', formValue.assessment?.rentManagementFee);
        }
      }
      setValue('maintenanceFee', formValue.assessment?.maintenanceFee as number);
    }
  }, [formValue]);

  const onClickAddress = (pref: string, city: string, town: string) => {
    clearErrors();
    setPrefName(pref);
    setCityName(city);
    setTownName(town);
    setValue('address', town);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit: SubmitHandler<FormState> = (data) => {
    const addressInfo: AssessmentAddress = {
      prefectureName: prefName,
      cityName: cityName,
      townName: townName,
      // 路線名、駅名、駅徒歩分のデータがDoormanMansionにないので仮の定数を設定
      // 査定結果に影響していないが個人情報画面から戻ってきたときに値を保持しておくため設定
      lineName: data.lineName,
      stationName: data.stationName,
      walkMinute: data.walkMinute,
    };
    const mansionInfo: AssessmentMansion = {
      mansionName: data.mansionName as string,
      builtDate: jpFormatToyyyymmddString(data.builtDateYear.toString(), data.builtDateMonth.toString()),
      structure: data.structure as Structure,
    };
    const roomInfo: AssessmentRoom = {
      floorPlan: data.floorPlan,
      story: Number(data.story),
      unitSize: data.unitSize,
      bathroomType: data.bathroomType as BathroomType,
    };

    // Assessmentの型にあてはめる
    const assessmentData: Assessment = {
      addressInfo: addressInfo,
      mansionInfo: mansionInfo,
      roomInfo: roomInfo,
      tenancyStatus: data.tenancyStatus as TenancyStatus,
      rentManagementStatus: data.rentManagementStatus as RentManagementStatus,
      maintenanceFee: data.maintenanceFee,
      lastName: '',
      firstName: '',
      lastNameKana: '',
      firstNameKana: '',
      mailAddress: '',
    };
    if (data.tenancyStatus === TenancyStatus.LEASE || data.tenancyStatus === TenancyStatus.VACANCY) {
      assessmentData.rentPrice = data.rentPrice;
      if (data.rentManagementStatus === RentManagementStatus.OTHER) {
        assessmentData.rentType = watchRentType as RentType;
        assessmentData.rentManagementFee = data.rentManagementFee;
      }
    }
    if (watchRentManagementFeeType && data.tenancyStatus === TenancyStatus.VACANCY) {
      assessmentData.rentManagementFeeType = watchRentManagementFeeType as RentManagementFeeType;
      if (watchRentManagementFeeType === RentManagementFeeType.YEN) {
        assessmentData.rentManagementFee = data.rentManagementFeeYen;
      } else {
        assessmentData.rentManagementFeeRate = data.rentManagementFeeRate;
      }
    }

    const newAssessment: Assessment = {
      ...assessmentData,
      ...personalInfoDetail,
      partnerId: getPartneridForSessionStorage(),
    } as Assessment;
    if (personalInfoDetail) {
      dispatch(assessment.operations.createAssessment(newAssessment));
      setAssessmentFlg(true);
    } else {
      dispatch(
        assessment.operations.keepAssessmentFormValue({ assessment: assessmentData, isFromSearchMansion: false })
      );
      navigate(Routings.information.location, {
        state: { assessmentFormValue: { assessment: assessmentData, isFromSearchMansion: false } },
      });
    }
  };

  useEffect(() => {
    if (!isFromInformation && doormaLines.lines?.[0]) {
      setValue('lineName', doormaLines.lines[0].name as string);
    }
  }, [doormaLines]);

  useEffect(() => {
    if (!isFromInformation && doormaStations.stations?.[0]) {
      setValue('stationName', doormaStations.stations[0].name as string);
    }
  }, [doormaStations]);

  const watchTenancyStatus = useWatch({
    control,
    name: 'tenancyStatus',
    defaultValue:
      isFromInformation && formValue.assessment?.tenancyStatus
        ? formValue.assessment?.tenancyStatus
        : TenancyStatus.LEASE,
  });

  /**
   * 入居状況が変更されたときに動的表示項目をリセットする処理。
   */
  useEffect(() => {
    if (!isFirstRender) {
      if (watchTenancyStatus === TenancyStatus.LIVE) {
        resetField('rentPrice');
        resetField('rentManagementFee');
        resetField('rentManagementFeeYen');
        resetField('rentManagementFeeRate');
      } else if (watchTenancyStatus === TenancyStatus.LEASE) {
        resetField('rentManagementFee');
        resetField('rentManagementFeeYen');
        resetField('rentManagementFeeRate');
      }
    }
  }, [watchTenancyStatus]);

  const watchRentManagementStatus = useWatch({
    control,
    name: 'rentManagementStatus',
    defaultValue:
      isFromInformation && formValue.assessment?.rentManagementStatus
        ? formValue.assessment?.rentManagementStatus
        : RentManagementStatus.SUBLEASE,
  });

  /**
   * 賃貸管理状況が変更されたときに動的表示項目をリセットする処理
   */
  useEffect(() => {
    if (!isFirstRender) {
      if (watchRentManagementStatus === RentManagementStatus.SUBLEASE) {
        resetField('rentManagementFee');
        resetField('rentManagementFeeYen');
        resetField('rentManagementFeeRate');
      }
    }
  }, [watchRentManagementStatus]);

  const watchRentManagementFeeType = useWatch({
    control,
    name: 'rentManagementFeeType',
    defaultValue:
      isFromInformation && formValue.assessment?.rentManagementFeeType
        ? formValue.assessment?.rentManagementFeeType
        : RentManagementFeeType.YEN,
  });

  useEffect(() => {
    if (watchRentManagementFeeType === RentManagementFeeType.YEN) {
      setValue('rentManagementFeeRate', undefined);
      clearErrors('rentManagementFeeRate');
    } else if (watchRentManagementFeeType === RentManagementFeeType.PERCENT) {
      setValue('rentManagementFeeYen', undefined);
      clearErrors('rentManagementFeeYen');
    }
  }, [watchRentManagementFeeType]);

  const watchRentType = useWatch({
    control,
    name: 'rentType',
    defaultValue:
      isFromInformation && formValue.assessment?.rentType ? formValue.assessment?.rentType : RentType.RESIDENT,
  });
  const watchBathroomType = useWatch({
    control,
    name: 'bathroomType',
    defaultValue:
      isFromInformation && formValue.assessment?.roomInfo?.bathroomType
        ? formValue.assessment?.roomInfo?.bathroomType
        : BathroomType.SEPARATE_BATH_AND_TOILET,
  });

  const handleOnSelectTrainLine = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    if (ev.target.value) {
      doormaLines.lines?.forEach((line) => {
        if (ev.target.value === line.name) dispatch(doorman.operations.getStations(line.id as number));
      });
    }
  };

  const createYearSelectBox = () => {
    const list = [];
    for (const year of Years) {
      list.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return <>{list}</>;
  };

  const createMonthSelectBox = () => {
    const list = [];

    for (let i = 1; i <= 12; i++) {
      list.push(<option key={i}>{i.toString()}</option>);
    }
    return <>{list}</>;
  };

  return (
    <Main>
      <PageTitle>マンションAI査定</PageTitle>
      <InnerMain>
        <GuideTextWrapepr>
          <GuideText>物件情報をご入力ください　</GuideText>
        </GuideTextWrapepr>
        <FormContainer>
          <tbody>
            <Row>
              <Th>
                <InnerTh>
                  <ThLabel htmlFor="mansionName">マンション名</ThLabel>
                </InnerTh>
              </Th>
              <Td style={{ paddingTop: isSpMode ? '20px' : '25px' }}>
                <InnerTdWrapper>
                  <InnerTd>
                    <Controller
                      name="mansionName"
                      control={control}
                      render={({ field }) => (
                        <MansionTextField
                          placeholder="例：ワークスマンション"
                          type="text"
                          id="mansionName"
                          {...field}
                        />
                      )}
                    />
                  </InnerTd>
                  <ErrorMessage>{errors.mansionName && errors.mansionName?.message}</ErrorMessage>
                </InnerTdWrapper>
              </Td>
            </Row>
            <Row>
              <Th>
                <InnerTh>
                  <ThLabel htmlFor="">住所</ThLabel>
                  <Required>必須</Required>
                </InnerTh>
              </Th>
              <AddressTd>
                <AddressArea>
                  {townName !== '' && (
                    <InnerTd style={{ padding: '1%' }}>{`${prefName}${cityName}${townName}`}</InnerTd>
                  )}
                  {!isSpMode && <ErrorMessage>{errors.address && errors.address?.message}</ErrorMessage>}
                  <ReSelectAddressButton onClick={() => setIsModalOpen(true)}>住所を選び直す</ReSelectAddressButton>
                </AddressArea>
                {isSpMode && <ErrorMessage>{errors.address && errors.address?.message}</ErrorMessage>}
              </AddressTd>
            </Row>
            <LineRow>
              <LineTh>
                <InnerTh>
                  <ThLabel>最寄駅・路線</ThLabel>
                  <Required>必須</Required>
                </InnerTh>
              </LineTh>
              <LineTd>
                <LineInnerTd>
                  <InnerTdLabel htmlFor="lineName">路線名</InnerTdLabel>
                  <Controller
                    name="lineName"
                    control={control}
                    render={() => (
                      <StyledSelect
                        id="lineName"
                        {...register('lineName')}
                        onChange={(e) => {
                          handleOnSelectTrainLine(e);
                        }}
                      >
                        {doormaLines.lines &&
                          doormaLines.lines.map((trainLine) => (
                            <option value={trainLine.name} key={trainLine.id}>
                              {trainLine.name}
                            </option>
                          ))}
                      </StyledSelect>
                    )}
                  />
                </LineInnerTd>
              </LineTd>
            </LineRow>
            <LineRow>
              {!isSpMode && <LineTh style={{ borderTop: 'hidden' }} />}
              <LineTd style={{ paddingTop: '0px' }}>
                <LineInnerTd>
                  <InnerTdLabel htmlFor="stationName">駅名</InnerTdLabel>
                  <Controller
                    name="stationName"
                    control={control}
                    render={({ field }) => (
                      <StyledSelect id="stationName" {...field}>
                        {doormaStations.stations &&
                          doormaStations.stations.map((stations) => <option key={stations.id}>{stations.name}</option>)}
                      </StyledSelect>
                    )}
                  />
                </LineInnerTd>
              </LineTd>
            </LineRow>
            <LineRow>
              {!isSpMode && <LineTh style={{ borderTop: 'hidden' }} />}
              <LineTd style={{ paddingTop: '0px' }}>
                <InnerWalkMinuteTd>
                  <InnerTdLabel htmlFor="walkMinute">徒歩分</InnerTdLabel>
                  <SmallTextFieldWrapper>
                    <Controller
                      name="walkMinute"
                      control={control}
                      render={({ field }) => (
                        <ControllerTextFieldWrapper>
                          <SmallTextField placeholder="例：5" type="text" id="walkMinute" {...field} />
                          <TdLabel>分</TdLabel>
                        </ControllerTextFieldWrapper>
                      )}
                    />
                    <ErrorMessage style={{ whiteSpace: 'nowrap' }}>
                      {errors.walkMinute && errors.walkMinute?.message}
                    </ErrorMessage>
                  </SmallTextFieldWrapper>
                </InnerWalkMinuteTd>
              </LineTd>
            </LineRow>
            <MansionDetailRow>
              <MansionDetailTh>
                <InnerTh>
                  <ThLabel>詳細情報</ThLabel>
                  <Required>必須</Required>
                </InnerTh>
              </MansionDetailTh>
              <MansionDetailTdFirstLine>
                <InnerTdWrapper>
                  <InnerTd>
                    <InnerTdLabel htmlFor="floorPlan" style={{ marginBottom: '0px' }}>
                      間取り
                    </InnerTdLabel>
                    <SmallTextFieldWrapper>
                      <Controller
                        name="floorPlan"
                        control={control}
                        render={({ field }) => (
                          <StyledSelect id="floorPlan" {...field}>
                            <option key={0} value="" hidden>
                              選択して下さい
                            </option>
                            {Object.entries(FloorPlanArray).map(([key, floorPlan]) => (
                              <option key={key}>{floorPlan.value}</option>
                            ))}
                          </StyledSelect>
                        )}
                      />
                      <ErrorMessage>{errors.floorPlan && errors.floorPlan?.message}</ErrorMessage>
                    </SmallTextFieldWrapper>
                  </InnerTd>
                </InnerTdWrapper>
              </MansionDetailTdFirstLine>
            </MansionDetailRow>
            <MansionDetailRow style={{ borderTop: '0px' }}>
              {!isSpMode && <MansionDetailTh />}
              <MansionDetailTd>
                <InnerTd style={{ flexDirection: 'row' }}>
                  <InnerTdLabel htmlFor="story">所在階</InnerTdLabel>
                  <SmallTextFieldWrapper>
                    <Controller
                      name="story"
                      control={control}
                      render={({ field }) => (
                        <ControllerTextFieldWrapper>
                          <SmallTextField placeholder="例：1" type="text" id="story" {...field} />
                          <TdLabel>階</TdLabel>
                        </ControllerTextFieldWrapper>
                      )}
                    />
                    <ErrorMessage style={{ whiteSpace: 'nowrap' }}>
                      {errors.story && errors.story?.message}
                    </ErrorMessage>
                  </SmallTextFieldWrapper>
                </InnerTd>
              </MansionDetailTd>
            </MansionDetailRow>
            <MansionDetailRow style={{ borderTop: '0px' }}>
              {!isSpMode && <MansionDetailTh />}
              <MansionDetailTd>
                <InnerTd style={{ flexDirection: 'row' }}>
                  <InnerTdLabel htmlFor="unitSize">専有面積</InnerTdLabel>
                  <SmallTextFieldWrapper>
                    <Controller
                      name="unitSize"
                      control={control}
                      render={({ field }) => (
                        <ControllerTextFieldWrapper>
                          <SmallTextField placeholder="例：10" type="text" id="unitSize" {...field} />
                          <TdLabel>㎡</TdLabel>
                        </ControllerTextFieldWrapper>
                      )}
                    />
                    <ErrorMessage style={{ whiteSpace: 'nowrap' }}>
                      {errors.unitSize && errors.unitSize?.message}
                    </ErrorMessage>
                  </SmallTextFieldWrapper>
                </InnerTd>
              </MansionDetailTd>
            </MansionDetailRow>
            <MansionDetailRow style={{ borderTop: '0px' }}>
              {!isSpMode && <MansionDetailTh />}
              <MansionDetailTd>
                <InnerTd>
                  <InnerTdLabel htmlFor="structure" style={{ marginBottom: '0px' }}>
                    建物構造
                  </InnerTdLabel>
                  <SmallTextFieldWrapper>
                    <Controller
                      name="structure"
                      control={control}
                      render={({ field }) => (
                        <StyledSelect id="structure" {...field}>
                          <option key={0} value="" hidden>
                            選択して下さい
                          </option>
                          {Object.entries(StructureArray).map(([key, structure]) => (
                            <option key={key}>{structure.value}</option>
                          ))}
                        </StyledSelect>
                      )}
                    />
                    <ErrorMessage>{errors.structure && errors.structure?.message}</ErrorMessage>
                  </SmallTextFieldWrapper>
                </InnerTd>
              </MansionDetailTd>
            </MansionDetailRow>
            <MansionDetailRow style={{ borderTop: '0px' }}>
              {!isSpMode && <MansionDetailTh />}
              <MansionDetailTd>
                <InnerTdWrapper>
                  <InnerTd style={{ height: isSpMode ? '100px' : '24px' }}>
                    <InnerTdLabel htmlFor="builtDateYear">築年月</InnerTdLabel>
                    <BuildDateWrapper>
                      <Controller
                        name="builtDateYear"
                        control={control}
                        render={({ field }) => (
                          <YearSelect id="builtDateYear" {...field}>
                            {createYearSelectBox()}
                          </YearSelect>
                        )}
                      />
                      <TdLabel htmlFor="builtDateMonth">年</TdLabel>
                      {errors.builtDateYear && <ErrorMessage>{errors.builtDateYear?.message}</ErrorMessage>}
                      <Controller
                        name="builtDateMonth"
                        control={control}
                        render={({ field }) => (
                          <MonthSelect id="builtDateMonth" {...field}>
                            {createMonthSelectBox()}
                          </MonthSelect>
                        )}
                      />
                      <TdLabel>月</TdLabel>
                    </BuildDateWrapper>
                  </InnerTd>
                </InnerTdWrapper>
                <ErrorMessage>{errors.builtDateMonth && errors.builtDateMonth?.message}</ErrorMessage>
              </MansionDetailTd>
            </MansionDetailRow>
            <MansionDetailRow style={{ borderTop: '0px' }}>
              {!isSpMode && <MansionDetailTh />}
              <MansionDetailTd>
                <InnerTdWrapper>
                  <InnerTd style={{ height: isSpMode ? 'max-content' : '24px' }}>
                    <InnerTdLabel>浴室設備</InnerTdLabel>
                    <RadioButtonDetailArea>
                      <Controller
                        name="bathroomType"
                        control={control}
                        render={({ field }) => (
                          <RadioButton
                            {...field}
                            ref={null}
                            checked={watchBathroomType === BathroomType.SEPARATE_BATH_AND_TOILET}
                            id={'separate_bath_and_toilet'}
                            name={'bathroomType'}
                            label={`${BathroomType.SEPARATE_BATH_AND_TOILET}`}
                            value={BathroomType.SEPARATE_BATH_AND_TOILET}
                          />
                        )}
                      />
                      <Controller
                        name="bathroomType"
                        control={control}
                        render={({ field }) => (
                          <RadioButton
                            {...field}
                            ref={null}
                            checked={watchBathroomType === BathroomType.UNIT_BATH}
                            id={'unit_bath'}
                            name={'bathroomType'}
                            label={`${BathroomType.UNIT_BATH}`}
                            value={BathroomType.UNIT_BATH}
                          />
                        )}
                      />
                    </RadioButtonDetailArea>
                  </InnerTd>
                </InnerTdWrapper>
                <ErrorMessage>{errors.bathroomType && errors.bathroomType?.message}</ErrorMessage>
              </MansionDetailTd>
            </MansionDetailRow>
          </tbody>
        </FormContainer>
        <GuideTextWrapepr>
          <GuideText>諸条件をご入力ください</GuideText>
        </GuideTextWrapepr>
        <FormContainer>
          <tbody>
            <Row>
              <Th>
                <InnerTh>
                  <ThLabel htmlFor="tenancyStatus">入居状況</ThLabel>
                  <Required>必須</Required>
                </InnerTh>
              </Th>
              <Td>
                <InnerTd>
                  <RadioButtonArea>
                    <Controller
                      name="tenancyStatus"
                      control={control}
                      render={({ field }) => (
                        <RadioButton
                          {...field}
                          ref={null}
                          checked={watchTenancyStatus === TenancyStatus.LEASE}
                          id={'lease'}
                          name={'tenancyStatus'}
                          label={'賃貸中'}
                          value={TenancyStatus.LEASE}
                        />
                      )}
                    />
                    <Controller
                      name="tenancyStatus"
                      control={control}
                      render={({ field }) => (
                        <RadioButton
                          {...field}
                          ref={null}
                          checked={watchTenancyStatus === TenancyStatus.VACANCY}
                          id={'vacancy'}
                          name={'tenancyStatus'}
                          label={'空室'}
                          value={TenancyStatus.VACANCY}
                        />
                      )}
                    />
                    <Controller
                      name="tenancyStatus"
                      control={control}
                      render={({ field }) => (
                        <RadioButton
                          {...field}
                          ref={null}
                          checked={watchTenancyStatus === TenancyStatus.LIVE}
                          id={'live'}
                          name={'tenancyStatus'}
                          label={'居住中（自用）'}
                          value={TenancyStatus.LIVE}
                        />
                      )}
                    />
                  </RadioButtonArea>
                </InnerTd>
              </Td>
            </Row>
          </tbody>
          {/* 賃貸中選択時に表示 */}
          {watchTenancyStatus === TenancyStatus.LEASE && (
            <tbody>
              <Row>
                <Th>
                  <InnerTh>
                    <ThLabel htmlFor="rentManagementStatus">賃貸管理状況</ThLabel>
                    <Required>必須</Required>
                  </InnerTh>
                </Th>
                <Td>
                  <InnerTd>
                    <RadioButtonArea>
                      <Controller
                        name="rentManagementStatus"
                        control={control}
                        render={({ field }) => (
                          <RadioButton
                            {...field}
                            ref={null}
                            checked={watchRentManagementStatus === RentManagementStatus.SUBLEASE}
                            id={'sublease'}
                            name={'rentManagementStatus'}
                            label={'サブリース'}
                            value={RentManagementStatus.SUBLEASE}
                          />
                        )}
                      />
                      <Controller
                        name="rentManagementStatus"
                        control={control}
                        render={({ field }) => (
                          <RadioButton
                            {...field}
                            ref={null}
                            checked={watchRentManagementStatus === RentManagementStatus.OTHER}
                            id={'other'}
                            name={'rentManagementStatus'}
                            label={'サブリース以外'}
                            value={RentManagementStatus.OTHER}
                          />
                        )}
                      />
                    </RadioButtonArea>
                  </InnerTd>
                </Td>
              </Row>
            </tbody>
          )}
          {/* サブリース選択時の表示項目 */}
          {watchTenancyStatus === TenancyStatus.LEASE &&
            (watchRentManagementStatus === RentManagementStatus.SUBLEASE ? (
              <tbody>
                <Row>
                  <Th>
                    <InnerTh>
                      <ThLabel htmlFor="rentPrice">手取り賃料</ThLabel>
                      <Required>必須</Required>
                    </InnerTh>
                  </Th>
                  <Td>
                    <InnerTdWrapper>
                      <InnerTd>
                        <TextFieldWrapper>
                          <Controller
                            name="rentPrice"
                            control={control}
                            render={({ field }) => (
                              <TextField placeholder="例：100000" type="text" id="rentPrice" {...field} />
                            )}
                          />
                          <TdLabel>円/月</TdLabel>
                        </TextFieldWrapper>
                      </InnerTd>
                      <ErrorMessage>{errors.rentPrice && errors.rentPrice?.message}</ErrorMessage>
                    </InnerTdWrapper>
                  </Td>
                </Row>
              </tbody>
            ) : (
              <tbody>
                <RentPriceRow>
                  <Th>
                    <InnerTh>
                      <ThLabel htmlFor="rentType">賃料</ThLabel>
                      <Required>必須</Required>
                    </InnerTh>
                  </Th>
                  <Td>
                    <InnerTd>
                      <Controller
                        name="rentType"
                        control={control}
                        render={({ field }) => (
                          <RentTypeSelect
                            id="rentType"
                            {...field}
                            defaultValue={isFromInformation ? formValue?.assessment?.rentType : PickedRentType.RESIDENT}
                          >
                            {Object.entries(PickedRentType).map(([key, value]) => (
                              <SelectOption key={key}>{value}</SelectOption>
                            ))}
                          </RentTypeSelect>
                        )}
                      />
                    </InnerTd>
                  </Td>
                </RentPriceRow>
                <RentPriceRow style={{ borderTop: 'hidden' }}>
                  {!isSpMode && <Th />}
                  <Td style={{ paddingTop: '0px' }}>
                    <InnerTdWrapper>
                      <InnerTd>
                        <TextFieldWrapper>
                          <Controller
                            name="rentPrice"
                            control={control}
                            render={({ field }) => <TextField placeholder="例：50000" type="text" {...field} />}
                          />
                          <TdLabel>円/月</TdLabel>
                        </TextFieldWrapper>
                      </InnerTd>
                      <ErrorMessage>{errors.rentPrice && errors.rentPrice?.message}</ErrorMessage>
                    </InnerTdWrapper>
                  </Td>
                </RentPriceRow>
                <Row>
                  <Th>
                    <InnerTh>
                      <ThLabel htmlFor="rentManagementFee">賃貸管理手数料</ThLabel>
                    </InnerTh>
                  </Th>
                  <RentManagementFeeTd>
                    <InnerTd>
                      <TextFieldWrapper>
                        <Controller
                          name="rentManagementFee"
                          control={control}
                          render={({ field }) => (
                            <TextField placeholder="例：50000" type="text" id="rentManagementFee" {...field} />
                          )}
                        />
                        <TdLabel>円/月</TdLabel>
                      </TextFieldWrapper>
                    </InnerTd>
                    <ErrorMessage>{errors.rentManagementFee && errors.rentManagementFee?.message}</ErrorMessage>
                  </RentManagementFeeTd>
                </Row>
              </tbody>
            ))}
          {/* 入居情報が空室の場合 */}
          {watchTenancyStatus === TenancyStatus.VACANCY && (
            <tbody>
              <Row>
                <Th>
                  <InnerTh>
                    <ThLabel htmlFor="rentPrice">募集家賃</ThLabel>
                  </InnerTh>
                </Th>
                <Td>
                  <InnerTdWrapper>
                    <InnerTd>
                      <TextFieldWrapper>
                        <Controller
                          name="rentPrice"
                          control={control}
                          render={({ field }) => (
                            <TextField placeholder="例：100000" type="text" id="rentPrice" {...field} />
                          )}
                        />
                        <TdLabel>円/月</TdLabel>
                      </TextFieldWrapper>
                    </InnerTd>
                    <ErrorMessage>{errors.rentPrice && errors.rentPrice?.message}</ErrorMessage>
                  </InnerTdWrapper>
                </Td>
              </Row>
              <Row>
                <Th>
                  <InnerTh>
                    <ThLabel htmlFor="rentManagementFeeRate">賃貸管理手数料</ThLabel>
                  </InnerTh>
                </Th>
                <Td>
                  <InnerTdWrapper>
                    <InnerTd>
                      <TextFieldWrapper>
                        {watchRentManagementFeeType === RentManagementFeeType.PERCENT && (
                          <Controller
                            name="rentManagementFeeRate"
                            control={control}
                            render={({ field }) => (
                              <RentManagementFeeTextField
                                placeholder="例：50"
                                type="text"
                                id="rentManagementFeeRate"
                                {...field}
                              />
                            )}
                          />
                        )}
                        {watchRentManagementFeeType === RentManagementFeeType.YEN && (
                          <Controller
                            name="rentManagementFeeYen"
                            control={control}
                            render={({ field }) => (
                              <RentManagementFeeTextField
                                placeholder="例：50000"
                                type="text"
                                id="rentManagementFeeRate"
                                {...field}
                              />
                            )}
                          />
                        )}
                        <TdLabel>
                          <Controller
                            name="rentManagementFeeType"
                            control={control}
                            render={() => (
                              <RentManagementFeeTypeSelect
                                id="rentManagementFeeType"
                                aria-label="賃貸管理手数料（単位）"
                                {...register('rentManagementFeeType')}
                                defaultValue={
                                  isFromInformation && formValue.assessment?.rentManagementFeeType
                                    ? formValue.assessment?.rentManagementFeeType
                                    : RentManagementFeeType.YEN
                                }
                              >
                                {Object.entries(RentManagementFeeType).map(([key, type]) => (
                                  <SelectOption key={key} value={type}>
                                    {type}
                                  </SelectOption>
                                ))}
                              </RentManagementFeeTypeSelect>
                            )}
                          />
                          {!isSpMode && <span style={{ paddingLeft: '10px' }}>/月</span>}
                        </TdLabel>
                        {isSpMode && <TdLabel>/月</TdLabel>}
                      </TextFieldWrapper>
                    </InnerTd>
                    <ErrorMessage>
                      {errors.rentManagementFeeRate && errors.rentManagementFeeRate?.message}
                      {errors.rentManagementFeeYen && errors.rentManagementFeeYen?.message}
                    </ErrorMessage>
                  </InnerTdWrapper>
                </Td>
              </Row>
            </tbody>
          )}
          <tbody>
            <Row>
              <Th>
                <InnerTh>
                  <ThLabel htmlFor="maintenanceFee">建物管理費と修繕積立金の合計</ThLabel>
                  <Required>必須</Required>
                </InnerTh>
              </Th>
              <Td>
                <InnerTdWrapper>
                  <InnerTd>
                    <TextFieldWrapper>
                      <Controller
                        name="maintenanceFee"
                        control={control}
                        render={({ field }) => (
                          <TextField placeholder="例：50000" type="text" id="maintenanceFee" {...field} />
                        )}
                      />
                      <TdLabel>円/月</TdLabel>
                    </TextFieldWrapper>
                  </InnerTd>
                  <ErrorMessage>{errors.maintenanceFee && errors.maintenanceFee?.message}</ErrorMessage>
                </InnerTdWrapper>
              </Td>
            </Row>
          </tbody>
        </FormContainer>
        {personalInfoDetail ? (
          <>
            <TermsInArea />
            <AIAssessmentButtonWrapper>
              <SubmitButton withTerms={true} label="AI査定結果を見る" onClick={handleSubmit(onSubmit)} />
            </AIAssessmentButtonWrapper>
          </>
        ) : (
          <AssessmentButtonWrapper>
            <AssessmentButton onClick={handleSubmit(onSubmit)}>この条件で査定する</AssessmentButton>
          </AssessmentButtonWrapper>
        )}
      </InnerMain>
      <AddressSelectModal isModalOpen={isModalOpen} closeModal={closeModal} onClickAddress={onClickAddress} />
    </Main>
  );
};

export default Condition;
