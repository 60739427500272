export interface JapaneseAlphabet {
  KANA_A: string;
  KANA_KA: string;
  KANA_SA: string;
  KANA_TA: string;
  KANA_NA: string;
  KANA_HA: string;
  KANA_MA: string;
  KANA_YA: string;
  KANA_RA: string;
  KANA_WA: string;
  [key: string]: string;
}

export const JAPANESE_ALPHABET_GROUP = {
  KANA_A: 'ア行',
  KANA_KA: 'カ行',
  KANA_SA: 'サ行',
  KANA_TA: 'タ行',
  KANA_NA: 'ナ行',
  KANA_HA: 'ハ行',
  KANA_MA: 'マ行',
  KANA_YA: 'ヤ行',
  KANA_RA: 'ラ行',
  KANA_WA: 'ワ行',
} as JapaneseAlphabet;

export type IJapaneseAlphabetGroupKey = keyof typeof JAPANESE_ALPHABET_GROUP;

export interface JapaneseAlphabets {
  KANA_A: string[];
  KANA_KA: string[];
  KANA_SA: string[];
  KANA_TA: string[];
  KANA_NA: string[];
  KANA_HA: string[];
  KANA_MA: string[];
  KANA_YA: string[];
  KANA_RA: string[];
  KANA_WA: string[];
  [key: string]: string[];
}

export const JAPANESE_ALPHABETS_GROUP = {
  KANA_A: ['ア', 'イ', 'ウ', 'エ', 'オ'],
  KANA_KA: ['カ', 'キ', 'ク', 'ケ', 'コ', 'ガ', 'ギ', 'グ', 'ゲ', 'ゴ'],
  KANA_SA: ['サ', 'シ', 'ス', 'セ', 'ソ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ'],
  KANA_TA: ['タ', 'チ', 'ツ', 'テ', 'ト', 'ダ', 'ヂ', 'ヅ', 'デ', 'ド'],
  KANA_NA: ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
  KANA_HA: ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'バ', 'ビ', 'ブ', 'ベ', 'ボ', 'パ', 'ピ', 'プ', 'ペ', 'ポ'],
  KANA_MA: ['マ', 'ミ', 'ム', 'メ', 'モ'],
  KANA_YA: ['ヤ', 'ユ', 'ヨ'],
  KANA_RA: ['ラ', 'リ', 'ル', 'レ', 'ロ'],
  KANA_WA: ['ワ', 'ヲ', 'ン'],
} as JapaneseAlphabets;
