import React from 'react';
import styled from 'styled-components';
import { Breakpoints, Prefectures } from '@/constants/constants';
import theme from '@/styles/theme';

type Props = {
  onClickPrefecture: (prefId: number, prefName: string) => void;
};

const Wrapper = styled.div`
  width: 100%;
  height: 144px;
  display: flex;
  align-items: center;
  padding: 40px 10% 60px 10%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    height: 335px;
    margin: 0 auto;
    padding-top: 40px;
  }
`;

const PrefectureButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    margin: 0px auto 20px auto;
  }
`;

const PseudoPrefectureButton = styled.button`
  display: flex;
  width: 190px;
  height: 44px;
  margin-right: 28px;
  padding-left: 20px;
  cursor: pointer;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  align-items: center;
  font: ${theme.fonts.bold_16_32};
  letter-spacing: 0px;
  color: #333333;
  white-space: nowrap;
  &:hover {
    background: #d1a827 0% 0% no-repeat padding-box;
    color: #ffffff;
  }
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Prefecture = ({ onClickPrefecture }: Props) => {
  const onClickPrefectureButton = (prefId: number, prefName: string) => {
    if (prefId && prefName) {
      onClickPrefecture(prefId, prefName);
    }
  };

  return (
    <Wrapper>
      <PrefectureButtonWrapper>
        {Object.entries(Prefectures).map(([, pref]) => (
          <PseudoPrefectureButton key={pref.id} onClick={() => onClickPrefectureButton(pref.id, pref.name)}>
            {pref.name}
          </PseudoPrefectureButton>
        ))}
      </PrefectureButtonWrapper>
    </Wrapper>
  );
};

export default React.memo(Prefecture);
