import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import Prefecture from './Prefecture';
import City from './City';
import Town from './Town';
import { KanaGroupDoormanCities } from '@/models/response/seller';
import { useDispatch, useSelector } from 'react-redux';
import * as doorman from '@/state/modules/doorman';
import { KanaGroupDoormanTowns } from '@/models/response/seller';
import { FetchStatus } from '@/models/enum/app';
import { cloneDeep } from 'lodash';
import { DoormanCity, DoormanTown } from 'build/generated-resources/kusabi-frontgw-spec';
import { JAPANESE_ALPHABET_GROUP, JAPANESE_ALPHABETS_GROUP } from '@/models/type/seller';
import { Breakpoints } from '@/constants/constants';
import theme from '@/styles/theme';
import { useMediaQuery } from 'react-responsive';
import ModalCloseIcon from '@/components/atoms/Icons/ModalCloseIcon';

const StyledReactModal = styled(ReactModal)`
  width: 95%;
  max-width: 1062px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Header = styled.div`
  width: 100%;
  padding: 23px 20px 23px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: #f2f2f2;
  &.caution {
    background: #fae6e6;
  }
  font: ${theme.fonts.normal_16_24};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 23px 20px 23px 20px;
  }
`;

const AddressHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const HeaderFirstLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconButton = styled.button<{ isPrefecture?: boolean }>`
  ${({ isPrefecture }) => isPrefecture && 'margin-left: auto;'};
`;

const TitleLeft = styled.div`
  width: 207px;
  height: 24px;
  white-space: nowrap;
  font: ${theme.fonts.normal_16_24};
`;

const SelectedAddressName = styled.div`
  width: fit-content;
  height: 24px;
  text-align: left;
  font: ${theme.fonts.bold_16_32};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  align-items: center;
  display: flex;
  margin-left: 8%;
  white-space: nowrap;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 20px auto 15px 0px;
  }
`;

const BackButton = styled.button`
  width: 190px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  text-align: center;
  font: ${theme.fonts.bold_14_33};
  letter-spacing: 0px;
  color: #4c586f;
  opacity: 1;
  white-space: nowrap;
  margin-left: auto;
  margin-right: 3%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin-left: 0px;
    margin-right: auto;
  }
`;

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  onClickAddress: (prefName: string, cityName: string, townName: string) => void;
};

type DisplayType = 'PREFECTURE' | 'CITY' | 'TOWN';

const AddressSelectModal = ({ isModalOpen, closeModal, onClickAddress }: Props) => {
  const dispatch = useDispatch();
  const [displayType, setDisplayType] = useState<DisplayType>('PREFECTURE');
  const [pref, setPref] = useState({ name: '', id: 0 });
  const [cityName, setCityName] = useState('');
  const [kanaGroupDoormanCities, setKanaGroupDoormanCities] = useState<KanaGroupDoormanCities>();
  const [kanaGroupDoormanTowns, setKanaGroupDoormanTowns] = useState<KanaGroupDoormanTowns>();
  const fetchDoormanCitiesStatus = useSelector(doorman.selectors.fetchDoormanCitiesStatus);
  const fetchDoormanTownsStatus = useSelector(doorman.selectors.fetchDoormanTownsStatus);
  const doormanCities = useSelector(doorman.selectors.getDoormanCities);
  const doormanTowns = useSelector(doorman.selectors.getDoormanTowns);
  const doormaLines = useSelector(doorman.selectors.getDoormanLines);
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });

  const handleOnMoveto = (nextType: DisplayType) => {
    setDisplayType(nextType);
  };

  const moveTo = (type: string) => {
    const moveToButton = (type: DisplayType, moveToButtonTitle: string) => {
      return <BackButton onClick={() => handleOnMoveto(type)}>{moveToButtonTitle}</BackButton>;
    };
    switch (type) {
      case 'PREFECTURE':
        return <></>;
      case 'CITY':
        return moveToButton('PREFECTURE', '都道府県を選び直す');
      case 'TOWN':
        return moveToButton('CITY', '市区町村を選び直す');
      default:
        return <></>;
    }
  };

  const onClickCityName = (cityName: string, cityId: number) => () => {
    setCityName(cityName);
    dispatch(doorman.operations.getTowns(cityId));
  };

  useEffect(() => {
    // DoormanTowns取得結果を五十音順でソート＆グルーピング
    if (fetchDoormanTownsStatus.status === FetchStatus.DONE) {
      if (doormanTowns.towns) {
        const copiedDoormanTowns = cloneDeep(doormanTowns.towns);
        const sortedDoormanTowns = copiedDoormanTowns.sort((a: DoormanTown, b: DoormanTown) => {
          return a.kana?.localeCompare(b.kana as string, 'ja') as number;
        });
        const groupDoormanTowns: KanaGroupDoormanTowns = {};
        Object.keys(JAPANESE_ALPHABET_GROUP).forEach((key) => {
          const kanagroupDoormanCity: DoormanTown[] = [];
          sortedDoormanTowns.forEach((obj) => {
            const firstKana = obj.kana?.substr(0, 1) as string;
            if (JAPANESE_ALPHABETS_GROUP[key].includes(firstKana)) {
              kanagroupDoormanCity.push(obj);
            }
          });
          groupDoormanTowns[key] = kanagroupDoormanCity;
        });
        setKanaGroupDoormanTowns(groupDoormanTowns);
        setDisplayType('TOWN');
      }
    }
  }, [fetchDoormanTownsStatus]);

  useEffect(() => {
    // DoormanCity取得結果を五十音順でソート＆グルーピング
    if (fetchDoormanCitiesStatus.status === FetchStatus.DONE) {
      if (doormanCities.cities) {
        const copiedDoormanCities = cloneDeep(doormanCities.cities);
        const sortedDoormanCities = copiedDoormanCities.sort((a: DoormanCity, b: DoormanCity) => {
          return a.kana?.localeCompare(b.kana as string, 'ja') as number;
        });
        const groupDoormanCities: KanaGroupDoormanCities = {};
        Object.keys(JAPANESE_ALPHABET_GROUP).forEach((key) => {
          const kanagroupDoormanCity: DoormanCity[] = [];
          sortedDoormanCities.forEach((obj) => {
            const firstKana = obj.kana?.substr(0, 1) as string;
            if (JAPANESE_ALPHABETS_GROUP[key].includes(firstKana)) {
              kanagroupDoormanCity.push(obj);
            }
          });
          groupDoormanCities[key] = kanagroupDoormanCity;
        });
        setKanaGroupDoormanCities(groupDoormanCities);
        setDisplayType('CITY');
      }
    }
  }, [doormanCities]);

  const onClickTownName = (townName: string) => () => {
    dispatch(doorman.operations.getLines(pref.id));
    onClickAddress(pref.name, cityName, townName);
    closeModal();
  };

  useEffect(() => {
    if (doormaLines.lines?.[0]) {
      dispatch(doorman.operations.getStations(doormaLines.lines[0].id as number));
    }
  }, [doormaLines]);

  const initializeId = () => {
    setDisplayType('PREFECTURE');
  };

  const handleOnCloseModal = () => {
    closeModal();
  };

  const onClickPrefecture = (prefId: number, prefName: string) => {
    setPref({ name: prefName, id: prefId });
    dispatch(doorman.operations.getCities(prefId));
  };

  return (
    <StyledReactModal
      aria={{ labelledby: 'heading' }}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onAfterOpen={initializeId}
      style={{
        content: { outline: 'none' },
        overlay: { zIndex: 999999 },
      }}
    >
      {/* タイトル箇所 */}
      <Header>
        {!isSpMode && (
          <>
            <TitleLeft id="heading">
              {displayType === 'PREFECTURE' && '都道府県を選択してください'}
              {displayType === 'CITY' && '市区町村を選択してください'}
              {displayType === 'TOWN' && '町丁目を選択してください'}
            </TitleLeft>
            <SelectedAddressName>
              {displayType === 'CITY' && `${pref.name}`}
              {displayType === 'TOWN' && `${pref.name}${cityName}`}
            </SelectedAddressName>
            {moveTo(displayType)}
            <IconButton onClick={handleOnCloseModal} isPrefecture={displayType === 'PREFECTURE'}>
              <ModalCloseIcon />
            </IconButton>
          </>
        )}
        {isSpMode && (
          <AddressHeader>
            <HeaderFirstLine>
              <TitleLeft id="heading">
                {displayType === 'PREFECTURE' && '都道府県を選択してください'}
                {displayType === 'CITY' && '市区町村を選択してください'}
                {displayType === 'TOWN' && '町丁目を選択してください'}
              </TitleLeft>
              <IconButton onClick={handleOnCloseModal}>
                <ModalCloseIcon />
              </IconButton>
            </HeaderFirstLine>
            {displayType === 'CITY' && <SelectedAddressName>{pref.name}</SelectedAddressName>}
            {displayType === 'TOWN' && (
              <SelectedAddressName>
                {pref.name}
                {cityName}
              </SelectedAddressName>
            )}
            {moveTo(displayType)}
          </AddressHeader>
        )}
      </Header>
      {/* 都県選択 */}
      {displayType === 'PREFECTURE' && <Prefecture onClickPrefecture={onClickPrefecture} />}
      {/* 市町村選択 */}
      {displayType === 'CITY' && (
        <City cityList={kanaGroupDoormanCities as KanaGroupDoormanCities} onClickCityName={onClickCityName} />
      )}
      {/* 町丁目選択 */}
      {displayType === 'TOWN' && (
        <Town townList={kanaGroupDoormanTowns as KanaGroupDoormanTowns} onClickTownName={onClickTownName} />
      )}
    </StyledReactModal>
  );
};

export default AddressSelectModal;
