import React from 'react';
import styled from 'styled-components';
import { JAPANESE_ALPHABET_GROUP } from '@/models/type/seller';
import { KanaGroupDoormanCities } from '@/models/response/seller';
import theme from '@/styles/theme';
import { Breakpoints } from '@/constants/constants';
import { useMediaQuery } from 'react-responsive';
import Select from '@/components/atoms/Select';

type Props = {
  cityList: KanaGroupDoormanCities;
  onClickCityName: (cityName: string, cityId: number) => () => void;
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 1062px;
  height: 374px;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 0px 2.5%;
    height: 470px;
  }
`;

const Navbar = styled.nav`
  background-color: #ffffff;
  width: 100%;
  height: 44px;
  margin: 40px auto 30px auto;
  border: none;
  border-radius: 0;
`;

const NavBarList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  margin-bottom: 0px;
`;

const NavBarListItem = styled.li`
  list-style: none;
`;

const NavBarListLink = styled.button`
  width: 72px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 4px 4px 0px 0px;
  margin-right: 10px;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
  }
`;

const CityListContainer = styled.div`
  width: 810px;
  height: 260px;
  margin: 0px auto 0px auto;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    height: 300px;
  }
`;

const CityItemWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  width: 100%;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 0px 20px 0px 0px;
  }
`;

const CityItem = styled.div`
  width: calc(100% / 4);
  height: 24px;
  margin-bottom: 20px;
  text-decoration: none;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: calc(100% / 1);
  }
`;

const CityLink = styled.button`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  opacity: 1;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  color: #333333;
  :hover {
    color: #d1a827;
  }
`;

const SelectWrapper = styled.div`
  width: 345px;
  display: flex;
  direction: row;
  align-items: center;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  width: 305px;
  margin: 30px auto 30px auto;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const SelectOption = styled.option`
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const City = ({ cityList, onClickCityName }: Props) => {
  const isSpMode = useMediaQuery({ query: '(max-width: 900px)' });
  const onClickKanaLink = (e: { target: { getAttribute: (arg0: string) => string } }, isCityExist: boolean) => {
    if (!isCityExist) return;
    let keyName = '';
    for (const [key, value] of Object.entries(JAPANESE_ALPHABET_GROUP)) {
      if (value === e.target.getAttribute('id')) {
        keyName = key;
        break;
      }
    }
    const element = document.getElementById(keyName);
    element?.scrollIntoView();
  };

  const onChangeCity = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    let keyName = '';
    for (const [key] of Object.entries(JAPANESE_ALPHABET_GROUP)) {
      if (key === ev.currentTarget.value) {
        keyName = key;
        break;
      }
    }
    const element = document.getElementById(keyName);
    element?.scrollIntoView();
  };

  return (
    <Wrapper>
      {!isSpMode ? (
        <Navbar>
          <NavBarList>
            {Object.keys(JAPANESE_ALPHABET_GROUP).map((kanaKey) => (
              <>
                {cityList &&
                  Object.entries(cityList).map(([key, cities]) => (
                    <>
                      {kanaKey === key && (
                        <NavBarListItem key={key}>
                          <NavBarListLink
                            aria-disabled={!(cities.length > 0)}
                            style={{ color: cities.length > 0 ? '#333333' : '#cccccc' }}
                            id={JAPANESE_ALPHABET_GROUP[key]}
                            onClick={(e: any) => onClickKanaLink(e, cities.length > 0)}
                          >
                            {JAPANESE_ALPHABET_GROUP[key]}
                          </NavBarListLink>
                        </NavBarListItem>
                      )}
                    </>
                  ))}
              </>
            ))}
          </NavBarList>
        </Navbar>
      ) : (
        <SelectWrapper>
          <StyledSelect aria-label="市区町村を選択" onChange={onChangeCity}>
            {Object.entries(JAPANESE_ALPHABET_GROUP).map(([kanaKey, kana]) => (
              <>
                {cityList &&
                  Object.entries(cityList).map(([key, cities]) => (
                    <>
                      {kanaKey === key && (
                        <SelectOption disabled={!(cities.length > 0)} key={key} value={kanaKey}>
                          {kana}
                        </SelectOption>
                      )}
                    </>
                  ))}
              </>
            ))}
          </StyledSelect>
        </SelectWrapper>
      )}

      <CityListContainer>
        {cityList &&
          Object.entries(cityList).map(([key, cities]) => (
            <CityItemWrapper id={key} key={key}>
              {cities.map((city) => (
                <CityItem key={city.id}>
                  <CityLink onClick={onClickCityName(city.name as string, city.id as number)}>{city.name}</CityLink>
                </CityItem>
              ))}
            </CityItemWrapper>
          ))}
      </CityListContainer>
    </Wrapper>
  );
};

export default React.memo(City);
