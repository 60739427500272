import * as yup from 'yup';
import { YupErrorMessage } from '@/constants/messages';
import { REGEX_RATE, REGEX_STORY, REGEX_UNITSIZE } from '@/constants/constants';
import { RentManagementFeeType, TenancyStatus } from 'openapi/kusabi-frontgw-spec';

export const schema = yup.object({
  address: yup.string().required(YupErrorMessage.required).label('住所'),
  mansionName: yup.string().trim().max(255, YupErrorMessage.string.max).label('マンション名'),
  lineName: yup.string().trim().required(YupErrorMessage.required).label('路線名'),
  stationName: yup.string().trim().required(YupErrorMessage.required).label('駅名'),
  walkMinute: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .required(YupErrorMessage.required)
    .min(1, YupErrorMessage.number.min)
    .max(999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('駅徒歩分'),
  floorPlan: yup.string().trim().required(YupErrorMessage.required).label('間取り'),
  story: yup
    .string()
    .trim()
    .required(YupErrorMessage.required)
    .test('story_matches', YupErrorMessage.string.story, (value) => {
      if (value) {
        return REGEX_STORY.test(value.toString());
      }
      return true;
    })
    .label('所在階'),
  unitSize: yup
    .number()
    .typeError(YupErrorMessage.number.numeric)
    .required(YupErrorMessage.required)
    .moreThan(10, YupErrorMessage.number.moreThan)
    .max(499.99, YupErrorMessage.number.max)
    .test({
      name: 'unitSize',
      params: { digits: 2 },
      message: YupErrorMessage.number.digits,
      test: (value) => {
        if (value) {
          return REGEX_UNITSIZE.test(value.toString());
        }
        return true;
      },
    })
    .label('専有面積'),
  structure: yup.string().trim().required(YupErrorMessage.required).label('建物構造'),
  builtDateYear: yup.number().required(YupErrorMessage.required).label('築年月（年）'),
  builtDateMonth: yup.number().required(YupErrorMessage.required).label('築年月（月）'),
  tenancyStatus: yup.string().label('入居状況'),
  rentType: yup.string().label('賃料種類'),
  rentManagementStatus: yup.string().label('賃貸管理状況'),
  maintenanceFee: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .required(YupErrorMessage.required)
    .typeError(YupErrorMessage.number.numeric)
    .min(0, YupErrorMessage.number.min)
    .max(99999999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('建物管理費・修繕積立金'),
  rentPrice: yup
    .number()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .typeError(YupErrorMessage.number.numeric)
    .when('tenancyStatus', {
      is: (value: string) => [TenancyStatus.LEASE as string].includes(value),
      then: yup.number().required(YupErrorMessage.required).typeError(YupErrorMessage.number.numeric),
      otherwise: yup.number().nullable().typeError(YupErrorMessage.number.numeric),
    })
    .min(1, YupErrorMessage.number.min)
    .max(99999999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('賃料'),
  rentManagementFeeType: yup.string().label('賃貸管理手数料（種類）'),
  rentManagementFee: yup
    .number()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value))
    .typeError(YupErrorMessage.number.numeric)
    .min(0, YupErrorMessage.number.min)
    .max(99999999, YupErrorMessage.number.max)
    .integer(YupErrorMessage.number.integer)
    .label('賃貸管理手数料'),
  rentManagementFeeYen: yup
    .number()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .typeError(YupErrorMessage.number.numeric)
    .min(0, YupErrorMessage.number.min)
    .max(99999999, YupErrorMessage.number.max)
    .when('rentManagementFeeType', {
      is: RentManagementFeeType.YEN,
      then: yup
        .number()
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .typeError(YupErrorMessage.number.numeric)
        .integer(YupErrorMessage.number.integer),
      otherwise: yup.number().strip(),
    })
    .label('賃貸管理手数料'),
  rentManagementFeeRate: yup
    .number()
    .when('rentManagementFeeType', {
      is: RentManagementFeeType.PERCENT,
      then: yup
        .number()
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .typeError(YupErrorMessage.number.numeric)
        .min(0, YupErrorMessage.number.min)
        .max(99.99, YupErrorMessage.number.max)
        .test({
          name: 'rentManagementFeeRate',
          params: { digits: 2 },
          message: YupErrorMessage.number.digits,
          test: (value) => {
            if (value) {
              return REGEX_RATE.test(value.toString());
            }
            return true;
          },
        }),
      otherwise: yup.number().strip(),
    })
    .label('賃貸管理手数料（比率）'),
  bathroomType: yup.string().required(YupErrorMessage.required).label('浴室設備'),
});

export type FormState = yup.InferType<typeof schema>;
